type Subscription<T> = {
  id: number;
  callback: (data: T) => void;
};

export class EventBus {
  private events: { [key: string]: Array<Subscription<any>> } = {};
  private lastSubscriptionId = 0;

  subscribe<T>(event: string, callback: (data: T) => void): number {
    const subscription: Subscription<T> = {
      id: this.lastSubscriptionId++,
      callback,
    };
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(subscription);
    return subscription.id;
  }

  unsubscribe(event: string, id: number): void {
    const subscriptions = this.events[event];
    if (!subscriptions) {
      return;
    }
    const index = subscriptions.findIndex((subscription) => subscription.id === id);
    if (index !== -1) {
      subscriptions.splice(index, 1);
    }
  }

  publish<T>(event: string, data: T): void {
    const subscriptions = this.events[event];
    if (!subscriptions) {
      return;
    }
    subscriptions.forEach((subscription) => subscription.callback(data));
  }
}
